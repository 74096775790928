<template>
  <e-scrollable-page>
    <template slot="content">
      <validation-observer :vid="'orderObserver-' + orderId" ref="order_observer"
                           v-slot="{ invalid, changed, dirty }" slim>
      <e-form-wizard :steps="steps" :save-fn="saveOrder">
        <e-wizard-step slot="step1">
          <div class="row">
            <div class="col-12 col-md-6">
              <order-sender-view :id="orderId" :editable="true" :default-country="defaultCountry"
                                 v-model="formValues.sender"
                                 @input="senderSelected($event)"
                                 @cleared="$refs.senderDeliveryNotes.clear()">
                <template v-slot:default>
                  <validation-provider :vid="orderId + '-sender.deliveryNotes'">
                    <delivery-notes-view :id="orderId" :editable="true" ref="senderDeliveryNotes"
                                         :title="$t('shipment.sender.deliveryNotes')"
                                         v-model="formValues.deliveryNotes"
                                         :add-defaults="formValues.state == 'NEW'"
                                         :sender="formValues.sender"
                                         :product="formValues.product"
                                         :filter="n => n.identifier.startsWith('6')"
                    />
                  </validation-provider>
                </template>
              </order-sender-view>
            </div>

            <div class="col-12 col-md-6 pt-4 pt-md-0">
              <order-consignee-view :id="orderId" :editable="true" :default-country="defaultCountry"
                                    v-model="formValues.consignee"
                                    @cleared="$refs.consigneeDeliveryNotes.clear()">
                <template v-slot:default>
                  <validation-provider :vid="orderId + '-consignee.deliveryNotes'">
                    <delivery-notes-view :id="orderId" :editable="true" ref="consigneeDeliveryNotes"
                                         :title="$t('shipment.consignee.deliveryNotes')"
                                         v-model="formValues.deliveryNotes"
                                         :add-defaults="formValues.state == 'NEW'"
                                         :consignee="formValues.consignee"
                                         :product="formValues.product"
                                         :filter="n => !n.identifier.startsWith('6')"
                    />
                  </validation-provider>
                </template>
              </order-consignee-view>
            </div>

            <div class="col-12 col-md-12 pt-4">
              <order-invoice-recipient-view :id="orderId" :editable="true"
                                            v-model="formValues.invoiceRecipient"
                                            :default-recipient="defaultInvoiceRecipient" />
            </div>

          </div>
        </e-wizard-step>

        <e-wizard-step slot="step2">
          <div class="row">
            <div class="col-12 col-md-6">
              <order-characteristics-view :id="orderId" :editable="true" :value="formValues"
                                          :shipping-date-offset="shippingDateOffset"
                                          :waiver-customer="waiverCustomer"
                                          :required-fields="requiredFields"
                                          :customer-currency="customerCurrency"
                                          :branch-currency="branchCurrency"
                                          :supported-currencies="supportedCurrencies"
                                          :default-term-national="defaultIncoTermNational"
                                          :default-term-international="defaultIncoTermInternational"
                                          :shipping-time-from="shippingTimeFrom"
                                          :shipping-time-until="shippingTimeUntil"
                                          :shipping-time-from-fr="shippingTimeFromFr"
                                          :shipping-time-until-fr="shippingTimeUntilFr"
              />
            </div>

            <div class="col-12 col-md-6 pt-4 pt-md-0">
              <order-product-view :id="orderId" :editable="true" :value="formValues" />
            </div>
          </div>
        </e-wizard-step>

        <e-wizard-step slot="step3">
          <div class="row">
            <div class="col-12">
              <order-cargo-list :id="orderId" :editable="true" :value="formValues.cargoList"
                                :consignee-country="formValues?.consignee?.country"
                                :additional-palette-swap-countries="additionalPaletteSwapCountries"
                                :required-fields="requiredFields"
                                :optional-fields="optionalFields"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 pt-4">
              <order-shipment-info :id="orderId" :editable="true"
                                   :optional-fields="optionalFields"
                                   :mandatory-fields="mandatoryFields"
                                   :value="formValues.cargoList" />
            </div>
          </div>
        </e-wizard-step>
      </e-form-wizard>
      </validation-observer>
    </template>
  </e-scrollable-page>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "@emons/emons-vue"
import ShipmentService from "@/services/shipmentService";
import OrderSenderView from "@/components/OrderSenderView";
import OrderConsigneeView from "@/components/OrderConsigneeView";
import OrderInvoiceRecipientView from "@/components/OrderInvoiceRecipientView";
import OrderCharacteristicsView from "@/components/OrderCharacteristicsView";
import OrderProductView from "@/components/OrderProductView";
import DeliveryNotesView from "@/components/DeliveryNotesView";
import OrderShipmentInfo from "@/components/OrderShipmentInfo";
import OrderCargoList from "@/components/OrderCargoList";
import SettingsService from "@/services/settings.service"
import SenderAddressService from "@/services/address.service";
import AxiosServiceMixin from "@/components/mixins/AxiosServiceMixin";
import CurrencyService from "@/services/currency.service";
import CargoListTemplateService from "@/services/cargoListTemplate.service";
import InvoiceRecipientAddressService from "@/services/invoiceRecipientAddress.service";
import CustomerService from "@/services/customer.service";
import LocationService from "@/services/location.service";


export default {
  name: "OrderWizard",
  mixins: [AxiosServiceMixin],
  components: {
    OrderCargoList,
    OrderShipmentInfo,
    DeliveryNotesView,
    OrderProductView,
    OrderCharacteristicsView,
    OrderInvoiceRecipientView,
    OrderConsigneeView,
    OrderSenderView,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      orderId: '__unsaved__',
      steps: [
        {title: 'orders.wizard-steps.address-data', slot: 'step1'},
        {title: 'orders.wizard-steps.order-details', slot: 'step2'},
        {title: 'orders.wizard-steps.cargo-list', slot: 'step3'}
      ],
      formValues: ShipmentService.create(),
      settings: null
    }
  },
  mounted() {
    SettingsService.findAll().then(response => {
      this.$log('DEBUG', 'SETTINGS', response)
      this.settings = response.data
    })
  },
  methods: {
    senderSelected: function(sender) {
      if (!!sender.invoiceRecipient) {
        this.formValues.invoiceRecipient = {...sender.invoiceRecipient, _fromSender: true}
      } else if (this.formValues?.invoiceRecipient?._fromSender) {
        this.formValues.invoiceRecipient = null
      }
    },
    setCustomerDefaults: async function (shipment) {
      const location = await this.customerBranch
      const defaultCountry = location?.country || 'DE'
      if (!shipment.sender.country) {
        shipment.sender.country = defaultCountry;
      }
      if (!shipment.consignee.country) {
        shipment.consignee.country = defaultCountry;
      }

      const defaultSender = await this.defaultSender
      if (!!defaultSender) {
        shipment.sender = defaultSender
        if (!!defaultSender.invoiceRecipient) {
          shipment.invoiceRecipient = {...defaultSender.invoiceRecipient, _fromSender: true}
        }
      }
      const defaultConsignee = await this.defaultConsignee
      if (!!defaultConsignee) {
        shipment.consignee = defaultConsignee
      }

      const defaultCargoTemplate = await this.defaultCargoTemplate
      if (defaultCargoTemplate) {
        shipment.cargoList.items = [defaultCargoTemplate]
      }
      return shipment
    },
    saveOrder: function () {
      this.doSave(ShipmentService, this.formValues,
          () => {
            this.showMsgBox(this.$t('shipment.saved.short'), this.$t('shipment.saved', {shipment: this.formValues.shipmentNumber})).then(
                () => this.$router.push('/shipments/list')
            )
          },
          () => {},
          this.$refs['order_observer'],
          this.orderId + "-"
      )
      /*
      this.$root.$log("debug", "saving order...")
      const shipment = (await ShipmentService.save(this.formValues)).data
      this.showMsgBox(this.$t('shipment.saved.short'), this.$t('shipment.saved', {shipment: shipment.shipmentNumber})).then(
          () => this.$router.push('/shipments/list')
      )
       */
    },
    showMsgBox: function (title, message) {
      return this.$bvModal.msgBoxOk(message, {
        title: title,
        titleClass: 'pl-2',
        centered: true,
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
      })
    },
  },
  asyncComputed: {
    async supportedCurrencies() {
      return await CurrencyService.getSupportedCurrencies()
    },
    async customerData() {
      return await CustomerService.getSelf();
    },
    async customerBranch() {
      const customerData = await this.customerData

      if (customerData) {
        return (await LocationService.getEntityDetails(customerData._links.location.href))?.data
      }
    },
    async branchCurrency() {
      const location = this.customerBranch;

      if (location) {
        let currency = location.currency
        if (!currency) {
          currency = await CurrencyService.getDefault()
        }
        this.$log('DEBUG', 'branch currency: ', currency)
        return currency?.isoCode
      }
    },
    async defaultCountry() {
      const location = await this.customerBranch

      return location?.country || 'DE'
    },
    async defaultInvoiceRecipient() {
      const emonsCustomerId = this.formValues.emonsCustomerId
      return await InvoiceRecipientAddressService.getDefault(emonsCustomerId)
    },
  },
  computed: {
    defaultSender() {
      return SenderAddressService.getDefaultSenderAddress()
    },
    defaultConsignee() {
      return SenderAddressService.getDefaultConsigneeAddress()
    },
    defaultCargoTemplate() {
      return CargoListTemplateService.getDefault()
    },
    additionalPaletteSwapCountries: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'ADDITIONAL_PALETTE_SWAP_COUNTRIES')
        if (setting) {
          return setting.value
        }
      }
      return []
    },
    shippingDateOffset : function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'SHIPPING_DATE_OFFSET')
        if (setting) {
          return parseInt(setting.value)
        }
      }
      return 0
    },
    waiverCustomer: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'WAIVER_CUSTOMER')
        if (setting) {
          return setting.value
        }
      }
      return false
    },
    requiredFields: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'REQUIRED_FIELDS')
        if (setting) {
          return setting.value
        }
      }
      return []
    },
    optionalFields: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'OPTIONAL_FIELDS')
        if (setting) {
          return setting.value
        }
      }
      return []
    },
    mandatoryFields: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'ADMIN_MANDATORY_FIELDS')
        if (setting) {
          return setting.value
        }
      }
      return []
    },
    customerCurrency: function() {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'CURRENCY_DEFAULT')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    defaultIncoTermNational: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'INCOTERMS_NATIONAL')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    defaultIncoTermInternational: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'INCOTERMS_INTERNATIONAL')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    shippingTimeFrom: function() {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'SHIPPING_TIME_FROM')
        if (setting) {
          this.$log('DEBUG', 'SHIPPING TIME FROM: ', setting)
          return setting.value
        }
      }
      return ''
    },
    shippingTimeUntil: function() {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'SHIPPING_TIME_UNTIL')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    shippingTimeFromFr: function() {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'SHIPPING_TIME_FROM_FR')
        if (setting) {
          this.$log('DEBUG', 'SHIPPING TIME FROM: ', setting)
          return setting.value
        }
      }
      return ''
    },
    shippingTimeUntilFr: function() {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'SHIPPING_TIME_UNTIL_FR')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
  },
  watch: {
    "customerBranch": {
      immediate: true,
      handler: function(newVal) {
        if (newVal) {
          this.setCustomerDefaults(this.formValues)
        }
      }
    }
  }
}
</script>

<style type="text/css">

.nav-pills.nav-wizard > li.active > a.nav-link {
  background-color: #c00719;
}

.nav-pills.nav-wizard > li.active > a.nav-link:before {
  border-color: #c00719 #c00719 #c00719 transparent !important;
}

.nav-pills.nav-wizard > li.active > a.nav-link:after {
  border-color: transparent transparent transparent #c00719 !important;
}

.nav-pills.nav-wizard .nav-item .nav-link .wizard-step-title {
  color: #c00719;
}

.nav-pills.nav-wizard .nav-item.active .nav-link .wizard-step-title {
  color: #ffffff;
}

.btn-link {
  color: #c00719 !important;
}

</style>