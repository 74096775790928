import ApiService from "@/services/api.service";
import {log} from "@emons/emons-vue"
import {addWorkdays, dateToString, differenceInWorkdays, stringToDate} from "@/util/dateutils";

const rel = 'shipments'
const path = '/api/shipments';
const schema = {
    shipmentNumber: null,
    state: null,
    sender: {
        customerId: null,
        name: null,
        additionalName: null,
        street: null,
        zipCode: null,
        city: null,
        country: null,
        contactName: null,
        mobile: null,
        email: null,
        phone: null,
        web: null
    },
    neutralConsignor: {
        name: null,
        additionalName: null,
        street: null,
        zipCode: null,
        city: null,
        country: null,
        contactName: null,
        mobile: null,
        email: null,
        phone: null,
        web: null
    },
    consignee: {
        customerId: null,
        name: null,
        additionalName: null,
        street: null,
        zipCode: null,
        city: null,
        country: null,
        contactName: null,
        mobile: null,
        email: null,
        phone: null,
        web: null
    },
    invoiceRecipient: null,
    orderNumber: null,
    shippingDate: null,
    shippingDateUntil: null,
    shippingTimeFrom: null,
    shippingTimeUntil: null,
    shippingNotice: null,
    shippingNoticeCreated: null,
    shippingTerms: null,
    collectOnDelivery: {
        value: null,
        currency: null
    },
    insuranceValue: {
        value: null,
        currency: null
    },
    referenceNumber: null,
    deliveryDate: null,
    cargoList: {
        storingPos: null,
        cbm: null,
        loadingLength: null,
        loadingEquipmentQty: null,
        loadingEquipmentType: null,
        items: null // TODO
    },
    commissionedOn: null
}
const header = {
    shipmentNumber: {
        name: 'Sendung Nr.',
        col: 'col-1',
        property: 'shipmentNumber'
    },
    senderCustomerId: {
        name: 'KD-Nr.',
        col: 'col-1',
        property: 'sender.customerId'
    },
    senderName: {
        name: 'Versender',
        col: 'col-2',
        property: 'sender.name'
    }
}
const allStates = [
    {'name': 'Entwurf', 'state': 'DRAFT'},
    {'name': 'Avisiert', 'state': 'ANNOUNCED'},
    {'name': 'Ladeliste', 'state': 'CARGO_LIST_CREATED'},
    {'name': 'Beauftragt', 'state': 'FINALIZED'},
    {'name': 'Storniert', 'state': 'CANCELLED'},
]
/*
const filter = {
    state: {
        name: 'Status',
        rel: 'name',
        selectable: allStates,
        selected: [],
        negate: false,
        links: {},
        page: {},
    }
}
 */
const filter = null
const filterType = 'popover';
const sync = null
const initialSort = 'shipmentNumber'
const initialSortDirection = 'desc'
const projection = ''
const create = true

const ShipmentService = {
    getHeader: function () {
        return header
    },
    getFilterType: function () {
        return filterType
    },
    getFilter: function () {
        return filter
    },
    getSync: function () {
        return sync
    },
    getCreate: function () {
        return create
    },
    getInitialSort: function () {
        return initialSort
    },
    getInitialSortDirection: function () {
        return initialSortDirection
    },
    getProjection: function () {
        return projection
    },
    announceShipments: async function (announcementData) {
        let response = []
        if (announcementData?.passedShipments?.length == 1) {
            // single shipment - should be easy
            let shipment = announcementData.passedShipments[0]
            shipment.shippingDate = announcementData.dateFrom;
            shipment.shippingDateUntil = announcementData.dateUntil;
            shipment.shippingTimeFrom = announcementData.timeFrom;
            shipment.shippingTimeUntil = announcementData.timeUntil;
            shipment.shippingNotice = announcementData.notice;
            // [Azure:1310] if the announcement deliveryDate is null, the deliverDate of the shipment is set to null,
            //              even for shipments with just an optional shipping date set.
            //              For fixing this, the shipping date is recalculated for products with an optional shipping
            //              date set.
            if (shipment.deliveryDate != null && announcementData.deliveryDate == null && shipment.product?.deliveryDateIsOptional) {
                const shippingMax = shipment.shippingDateUntil==null?shipment.shippingDate:shipment.shippingDateUntil
                const deliveryMin = addWorkdays(stringToDate(shippingMax),  shipment.product?.deliveryDateWindow?.min, shipment?.consignee?.country)
                const current = stringToDate(shipment.deliveryDate)
                if (current.getTime() < deliveryMin.getTime()) {
                    shipment.deliveryDate = deliveryMin
                }
            } else {
                shipment.deliveryDate = announcementData.deliveryDate;
            }

            shipment.state = 'CREATE_ANNOUNCEMENT'


            let saved = (await this.save(shipment)).data
            response.push(saved)
        } else if (announcementData?.passedShipments?.length > 1) {
            // not implemented yet
            let shipments = announcementData.passedShipments
            for (const shipment of shipments) {
                try {
                    // complex part - set delivery date only if product has delivery date window
                    // [Azure:1310] only if delivery date is not optional
                    if (shipment.product?.deliveryDateWindow && !shipment.product?.deliveryDateIsOptional) {
                        if (announcementData.deliveryDate) {
                            // set to fixed delivery date
                            log('DEBUG', 'shipment %s has delivery date window and deliveryDate needs to be set to fixed date %s', shipment.shipmentNumber, announcementData.deliveryDate, shipment)
                            shipment.deliveryDate = announcementData.deliveryDate
                        } else if (shipment.deliveryDate) {
                            // set new delivery date based on days difference
                            log('DEBUG', 'shipment %s has delivery date window and deliveryDate needs to be set based on day difference', shipment.shipmentNumber, shipment)
                            let d1 = stringToDate(shipment.shippingDate), d2 = stringToDate(shipment.deliveryDate)
                            let diff = differenceInWorkdays(d1, d2, shipment?.consignee?.country)
                            let from = stringToDate(announcementData.dateFrom)
                            let target = addWorkdays(from, diff, shipment?.consignee?.country)
                            log('DEBUG', 'added difference of %s (d1 %s, d2 %s) workdays to shipping date %s - result %s', diff, d1, d2, from, target)
                            shipment.deliveryDate = dateToString(target)
                        }
                    } else {
                        log('DEBUG', 'shipment %s has no or optional delivery date window', shipment.shipmentNumber, shipment)
                    }
                    shipment.shippingDate = announcementData.dateFrom
                    shipment.shippingDateUntil = announcementData.dateUntil
                    shipment.shippingTimeFrom = announcementData.timeFrom
                    shipment.shippingTimeUntil = announcementData.timeUntil
                    shipment.shippingNotice = announcementData.notice
                    shipment.state = 'CREATE_ANNOUNCEMENT'

                    let saved = (await this.save(shipment)).data
                    response.push(saved)
                } catch (err) {
                    log('ERROR', err)
                }
            }
        } else {
            // no shipments to announce
            log('ERROR', "no shipments passed to announceShipments")
        }
        return response
    },
    findInFilter: async function (filterItem, searchText, queryDropDown) {
        console.log('queryDropDown', queryDropDown)
        if (queryDropDown?.length > 0)
            filterItem.selectable = allStates.filter(state => state.name.toLowerCase().includes(queryDropDown.toLowerCase()))
        else
            filterItem.selectable = allStates
    },
    find: async function (query,
                          filters,
                          projection,
                          sortProperty,
                          sortDirection,
                          size) {
        try {
            return ApiService.get(path, {
                params: {
                    query: query,
                    state: (filters?.state?.selected?.length > 0)
                        ? filters.state.selected.map((item) => item['state']).join(',')
                        : null,
                    projection: projection,
                    sort: sortProperty + ',' + sortDirection,
                    size: size != null ? size : 100
                },
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData) {

                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'] && parsedData['_embedded'][rel] ? parsedData['_embedded'][rel] : []
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "find():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log('error', 'Error querying ' + rel + ':', error)
        }
    },
    nextPage: async function (link) {
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        if (parsedData) {
                            let response = {
                                links: parsedData['_links'],
                                page: parsedData['page'],
                                items: parsedData['_embedded'] && parsedData['_embedded'][rel] ? parsedData['_embedded'][rel] : []
                            };

                            response.items.forEach(item => {
                                ApiService.ensureSchema(schema, item)
                            })

                            log("debug", "nextPage():", response)

                            return response
                        }
                    }
                }]
            })
        } catch (error) {
            log("error", "Error on next page:", error)
        }
    },
    getEntityDetails: async function (link) {
        try {
            return ApiService.get(link, {
                params: {},
                transformResponse: [function (data) {
                    if (data) {
                        let parsedData = JSON.parse(data)

                        log("debug", "getEntityDetails():", parsedData)

                        return parsedData
                    }
                }]
            })
        } catch (error) {
            log("error", "Error querying entity:", error)
        }
    },
    create: function () {
        log('debug', 'create() called...')
        return {
            isOpen: true,
            state: 'NEW',
            sender: {
                name: '',
                additionalName: '',
                emonsCustomerId: '',
                customerId: '',
                isRetail: false,
                isNeutral: false,
                types: ['SENDER'],
                street: '',
                country: '',
                zipCode: '',
                city: '',
                contactName: '',
                phone: '',
                email: '',
                autosendShippingDocuments: false
            },
            consignee: {
                name: '',
                additionalName: '',
                emonsCustomerId: '',
                customerId: '',
                isRetail: false,
                types: ['CONSIGNEE'],
                street: '',
                country: '',
                zipCode: '',
                city: '',
                contactName: '',
                mobile: '',
                email: '',
                phone: '',
                web: ''
            },
            invoiceRecipient: null,
            orderNumber: null,
            shippingDate: null,
            shippingTerms: '',
            collectOnDelivery: {
                value: null,
                currency: null
            },
            insuranceValue: {
                value: null,
                currency: null
            },
            referenceNumber: null,
            deliveryDate: null,
            product: null,
            cargoList: {
                storingPos: null,
                cbm: null,
                loadingLength: null,
                loadingEquipmentQty: null,
                loadingEquipmentType: null,
                items: [{
                    marking: null,
                    orderNumber: null,
                    colliQty: 1,
                    packingCode: null,
                    weight: null,
                    goods: null,
                    length: null,
                    width: null,
                    height: null,
                    hazmats: []
                }]
            },
            deliveryNotes: []
        };
    },
    save: async function (shipment) {
        if (!shipment.state || shipment.state == 'NEW') {
            shipment.state = 'DRAFT'
        }
        if (shipment._links && shipment._links.self) {
            return ApiService.put(shipment._links.self.href, shipment).then(
                response => {
                    if (response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        } else {
            return ApiService.post('/api/shipments', shipment).then(
                response => {
                    if (response.data) {
                        ApiService.ensureSchema(schema, response.data)
                    }
                    return response
                }
            )
        }
    },
    setCommissioned: async function (shipment) {
        if (!shipment._links?.commission?.href) {
            return {data: null}
        }
        shipment.state = 'FINALIZED'
        return ApiService.put(shipment._links.commission.href, shipment).then(
            response => {
                if (response.data) {
                    ApiService.ensureSchema(schema, response.data)
                }
                return response
            }
        )
    },
    delete: async function (shipment) {
        if (shipment._links && shipment._links.self) {
            return ApiService.delete(shipment._links.self.href)
        }
    },
    downloadLoadingList: async function (shipment) {
        return ApiService
            .get(shipment._links.loadinglist.href, {
                headers: {
                    Accept: 'application/pdf'
                },
                responseType: 'arraybuffer'
            }).then(
                response => {
                    let blob = new Blob([response.data], {type: 'application/pdf'})
                    let url = window.URL.createObjectURL(blob)
                    window.open(url)
                }
            );
    },
    createLoadingList: async function (shipments) {
        const response = await ApiService
            .post('/api/loadinglists', {shipmentNumbers: shipments.map(shipment => shipment.shipmentNumber)})
        response.data._embedded.loadinglists.forEach(loadingList => {
            ApiService
                .get(loadingList._links.self.href, {
                    headers: {
                        Accept: 'application/pdf'
                    },
                    responseType: 'arraybuffer'
                }).then(
                response => {
                    let blob = new Blob([response.data], {type: 'application/pdf'})
                    let url = window.URL.createObjectURL(blob)
                    window.open(url, '_blank')
                }
            )
        })
        const entityResponses = await Promise.all(shipments.map(async (s) => {
            return await this.getEntityDetails(s?._links?.self?.href)
        }))
        return entityResponses.map(r => r.data)
    },
    downloadLabels: async function (printerType, shipments, position) {
        if (shipments) {
            const params = new URLSearchParams([
                ['shipments', shipments.map(shipment => shipment.shipmentNumber)],
                ['printerType', printerType],
                ['labelPosition', position ? position : 0],
            ])
            ApiService
                .get("/api/shipments/labels", {
                    headers: {
                        Accept: 'application/pdf'
                    },
                    responseType: 'arraybuffer',
                    params: params
                })
                .then(response => {
                    let blob = new Blob([response.data], {type: 'application/pdf'})
                    let url = window.URL.createObjectURL(blob)

                    window.open(url)
                })
        }
    },
    exportXLS: async function (query = null,
                               filters = null,
                               sortProperty = 'shipmentNumber',
                               sortDirection = 'desc',
                               size = 100) {
        try {
            return ApiService.get("/api/shipments.xlsx", {
                headers: {
                    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                responseType: 'arraybuffer',
                params: {
                    query: query,
                    state: (filters?.state?.selected?.length > 0)
                        ? filters.state.selected.map((item) => item['state']).join(',')
                        : null,
                    sort: sortProperty + ',' + sortDirection,
                    size: size
                }
            }).then(
                response => {
                    let blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                    let fileURL = window.URL.createObjectURL(blob)

                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'shipments.xlsx');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                }
            )
        } catch (error) {
            log('error', 'Error exporting addresses:', error)
        }
    },
    sendInquiry: async function (shipment, inquiryText) {
        return await ApiService.post(shipment?._links?.inquiry?.href, JSON.stringify(inquiryText), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    sendPickUpOrder: async function (shipment, pickUpOrder) {
        return await ApiService
            .post(shipment?._links?.pickUpOrder?.href, JSON.stringify(pickUpOrder), {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                },
                responseType: 'arraybuffer'
            })
            .then(response => {
                let blob = new Blob([response.data], {type: 'application/pdf'})
                let url = window.URL.createObjectURL(blob)

                window.open(url)
            })
    }
}

export default ShipmentService