<template>
  <validation-provider ref="validationProvider" v-if="!disabled && (cityLookupSupported && null == this.lastLookup.error)" :vid="id" name="city" v-slot="{ classes, errors }"
                       :rules="rules"
                       slim>
    <div class="form-label-group">
      <v-select name="city" label="city" :aria-disabled="disabled"
          @open="onOpen" @close="onClose" @input="onInput" @option:selected="onSelect"
          :class="['city-select', 'form-control'].concat(value?'has-value':'').concat(classes)"
          :rules="rules" :disabled="disabled"
          :options="selectableCities || []" :reduce="(area) => area.city"
          :value="value">
      </v-select>
      <label class="required">{{ $t('address.city') }}</label>
      <span class="invalid-feedback">{{ errors[0] }}</span>
    </div>
  </validation-provider>
  <e-form-text-input v-else :id="id" name="city" :placeholder="$t('address.city')"
                     :rules="rules" :disabled="disabled"
                     :value="value" @input="$emit('input', $event)" />
</template>

<script>
import {validate, ValidationProvider} from '@emons/emons-vue'
import ZipCodeAreaService from "@/services/zipCodeArea.service";

var supportedCountries;
var lock;

export default {
  name: "CitySelect",
  components: {ValidationProvider},
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      default: ''
    },
    zipCode: {
      default: ''
    },
    country: {
      default: ''
    },
    rules: {
      type: String,
      default: 'required'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lastLookup: {
        country: null,
        zipCode: null,
        cities: null,
        city: null,
        error: null
      }
    }
  },
  asyncComputed: {
    async cityLookupSupported() {
      const country = this.country

      await this._loadSupportedCountries()
      return supportedCountries.includes(country)
    },
    async selectableCities() {
      const country = this.country
      const zipCode = this.zipCode
      const disabled = this.disabled

      await this._loadSupportedCountries()
      const cityoLookupSupported = supportedCountries.includes(country)

      if (disabled || !cityoLookupSupported) {
        return[]
      }

      const validation = await validate(zipCode, 'required|zipCode:' + country)

      if (!validation.valid) {
        // invalid country and/or zip code -> don't look up cities
        this.$emit('input', '')
        return []
      }

      let matchedCities = [], city
      if (country == this.lastLookup.country && zipCode == this.lastLookup.zipCode && this.lastLookup.error == null) {

        matchedCities = this.lastLookup.cities
        city = this.lastLookup.city
      } else {
        this.lastLookup.error = null
        city = this.value
        try {
          const response = await ZipCodeAreaService.find(
              zipCode, {country: {selected: [country]}}, "city")

          matchedCities = response?.data?.items
        } catch (lookupError) {
          this.lastLookup.error = lookupError
          if (city?.length > 0) {
            this.lastLookup.country = country
            this.lastLookup.zipCode = zipCode
            this.lastLookup.city = city
          }
          this.$log('ERROR', 'Error during city lookup', lookupError)
        }
      }

      if (matchedCities?.length > 0) {
        // city lookup returned some results -> check if previous city is contained
        let found = false
        if (city) {
          for (const item of matchedCities) {
            if (item.city && item.city === city) {
              //this.$log('debug', "City '" + city + "' found, no need to set value");
              found = true
            }
          }
        }

        // cache lookup result for non-empty responses
        this.lastLookup.country = country
        this.lastLookup.zipCode = zipCode
        this.lastLookup.cities = matchedCities
        this.lastLookup.city = found?city:matchedCities[0].city
        // set city to first result in case previous city not found (this currently applies to results with single item
        // as well as several items and could be distinguished further if desired)
        // always emit input - emit would only be required if city has changed (= first result is set)
        // but as we are caching previous result now we need to emit in this case too
        this.$emit('input', this.lastLookup.city)
        this.$nextTick(
            () => this.$refs.validationProvider?.validate()
        )
        return matchedCities
      } else {
        if (null == this.lastLookup.error) {
          // city lookup returned nothing -> must be non-existent zip-code -> reset city to empty
          this.$emit('input', '')
        } else {
          if (country == this.lastLookup.country && zipCode == this.lastLookup.zipCode) {
            this.$emit('input', this.lastLookup.city)
          }
        }
        this.$nextTick(
            () => this.$refs.validationProvider?.validate()
        )
        return []
      }
    }
  },
  methods: {
    async onOpen() {
      // UGLY UGLY UGLY hack:
      // - add event listeners for mouseenter / mouseleave on dropdown menu using plain old js
      // - as vue-custom-scrollbar doesn't emit for these yet events yet
      await this.$nextTick() // dropdown-menu might not be in DOM yet
      const menu = this.$el.querySelector('.vs__dropdown-menu')
      menu.addEventListener('mouseenter', this.mouseListener, false)
      menu.addEventListener('mouseleave', this.mouseListener, false)
    },
    onClose() {
      const menu = this.$el.querySelector('.vs__dropdown-menu')
      menu.removeEventListener('mouseenter', this.mouseListener, false)
      menu.removeEventListener('mouseleave', this.mouseListener, false)
      this.lockParentScroll(false)
    },
    onInput($event) {
      this.lastLookup.city = $event
      this.$emit('input', $event)
    },
    onSelect(zipCodeArea) {
      this.$emit('citySelect', zipCodeArea)
    },
    mouseListener(event) {
      this.lockParentScroll(event.type == 'mouseenter')
    },
    lockParentScroll(lock) {
      this.$eventBus.$emit('scroll:lock', lock)
    },
    _loadSupportedCountries: async function() {
      if (lock) await lock
      if (supportedCountries == null) {
        lock = ZipCodeAreaService.getSupportedCountries().then((result) => {
          supportedCountries = result
          this.$log('debug', 'supported countries', supportedCountries)
        })
        await lock
        lock = null
      }
    },
  }
}
</script>

<style>

.city-select .vs__search::placeholder,
.city-select .vs__dropdown-toggle {
  height: inherit;
  border: none;
  background-color: transparent !important;
}

.city-select.is-valid,
.was-validated .city-select:valid {
  /* 32 px (actions size) + original background position */
  background-position: right calc(32px + 0.3375em + .2625rem) center;
}

.city-select.is-invalid,
.was-validated .city-select:invalid {
  /* 14 px (actions size) + original background position */
  background-position: right calc(14px + 0.3375em + .2625rem) center;
}

.city-select.form-control {
  padding: unset !important;
}

.city-select.has-value.form-control-lg ~ label,
.city-select.vs--open.form-control-lg ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .825rem;
  color: #777;
}

.city-select.has-value.form-control-sm ~ label,
.city-select.vs--open.form-control-sm ~ label{
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .6rem;
  color: #777;
}

.city-select.has-value ~ label,
.city-select.vs--open ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: .675rem;
  color: #778;
}

.city-select .vs__clear {
  line-height: 10px;
}

.vs--single.vs--open .vs__selected {
  position: absolute;
  top: 8px;
}

.vs--disabled .vs__search {
  background-color: transparent !important;
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator {
  background-color: #e9ecef !important;
}

.city-select.form-control-lg .vs__selected,
.city-select.form-control-lg .vs__search {
  font-size: 1.1rem;
  line-height: 1.5;
  padding: .5rem 1rem;
}

.city-select.form-control-sm .vs__selected,
.city-select.form-control-sm .vs__search {
  font-size: .8rem;
  line-height: 1.2;
  padding: .45rem .25rem;
}

.city-select .vs__selected,
.city-select .vs__search {
  font-size: .9rem;
  line-height: 1.35;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.city-select.no-placeholder .vs__selected {
  margin: 0;
  position: absolute;
  top: 0
}

.city-select.no-placeholder .vs__search {
  margin: 0;
  position: relative;
}
</style>