var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: _vm.id + '-observer',
    attrs: {
      "slim": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$value, _vm$value$_links, _vm$value$_links$invo, _vm$invoiceRecipients, _vm$invoiceRecipients2, _vm$invoiceRecipients3, _vm$invoiceRecipients4, _vm$invoiceRecipients5, _vm$invoiceRecipients6, _vm$invoiceRecipients7, _vm$value2, _vm$value2$addresses, _vm$value2$addresses$, _vm$value2$addresses$2, _vm$value3, _vm$value3$addresses, _vm$value3$addresses$, _vm$value3$addresses$2, _vm$value4, _vm$value4$addresses, _vm$value4$addresses$, _vm$value4$addresses$2, _vm$value5, _vm$value5$_links, _vm$value5$_links$reg, _vm$value6, _vm$value6$_links, _vm$value6$_links$reg;

        var invalid = _ref.invalid,
            changed = _ref.changed,
            dirty = _ref.dirty,
            failed = _ref.failed,
            handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.onSubmit);
            }
          }
        }, [_c('b-tabs', {
          on: {
            "input": _vm.tabChanged
          }
        }, [_c('b-tab', {
          attrs: {
            "title": _vm.$t('customer.master-data')
          }
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-activated'
          }
        }, [_vm._v(_vm._s(_vm.$t('state')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-checkbox', {
          attrs: {
            "id": _vm.id + '-customer-activated',
            "name": "activated"
          },
          model: {
            value: _vm.value.activated,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "activated", $$v);
            },
            expression: "value.activated"
          }
        }, [_vm._v(" " + _vm._s(_vm.value.activated ? _vm.$t('state.enabled') : _vm.$t('state.disabled')) + " ")])], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-customerId'
          }
        }, [_vm._v(_vm._s(_vm.$t('customer.id')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text', {
          attrs: {
            "id": _vm.id + '-customerId',
            "value": _vm.value.customerId
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-location'
          }
        }, [_vm._v(_vm._s(_vm.$t('customer.branch')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-select', {
          attrs: {
            "id": _vm.id + '-customer-location',
            "name": "location",
            "placeholder": _vm.$t('customer.select-branch'),
            "rules": "required",
            "size": "sm"
          },
          model: {
            value: _vm.value.location,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "location", $$v);
            },
            expression: "value.location"
          }
        }, _vm._l(_vm.myLocations, function (location, idx) {
          return _c('option', {
            key: idx,
            domProps: {
              "value": location.abbreviation
            }
          }, [_vm._v(_vm._s(location.name) + " ")]);
        }), 0)], 1)])]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-name'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.name')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-name',
            "name": "name",
            "size": "sm",
            "rules": "required"
          },
          model: {
            value: _vm.value.name,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "name", $$v);
            },
            expression: "value.name"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-additionalName'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.additionalName')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-additionalName',
            "name": "name",
            "size": "sm"
          },
          model: {
            value: _vm.value.additionalName,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "additionalName", $$v);
            },
            expression: "value.additionalName"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-street'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.street')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-street',
            "name": "street",
            "size": "sm",
            "rules": "required"
          },
          model: {
            value: _vm.value.street,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "street", $$v);
            },
            expression: "value.street"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-zipCode'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.zipCode')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-zipCode',
            "name": "zipCode",
            "size": "sm",
            "rules": "required"
          },
          model: {
            value: _vm.value.zipCode,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "zipCode", $$v);
            },
            expression: "value.zipCode"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-city'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.city')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('e-form-text-input', {
          attrs: {
            "id": _vm.id + '-city',
            "name": "city",
            "size": "sm",
            "rules": "required"
          },
          model: {
            value: _vm.value.city,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "city", $$v);
            },
            expression: "value.city"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-3 col-form-label text-right",
          attrs: {
            "for": _vm.id + '-country'
          }
        }, [_vm._v(_vm._s(_vm.$t('address.country')))]), _c('div', {
          staticClass: "col-sm-9"
        }, [_c('v-select', {
          attrs: {
            "options": _vm.countries['selectable'],
            "filterable": false,
            "reduce": function reduce(country) {
              return country.alpha2Code;
            },
            "label": "alpha2Code"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.findCountries();
            },
            "search": function search(query, loading) {
              return _vm.findCountries(query);
            }
          },
          model: {
            value: _vm.value.country,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "country", $$v);
            },
            expression: "value.country"
          }
        })], 1)])])]), _c('div', {
          staticClass: "row mt-4"
        }, [_c('div', {
          staticClass: "col-md-6 offset-md-6"
        }, [_c('div', {
          staticClass: "form-group row justify-content-end"
        }, [!_vm.isSaved() || _vm.canModify() ? _c('div', {
          staticClass: "col-sm-3"
        }, [_c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-primary",
          attrs: {
            "id": "saveButton",
            "type": "submit",
            "disabled": !(changed || dirty) || invalid
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.saveEntity();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('controls.save')) + " ")])]) : _vm._e(), !_vm.isSaved() || _vm.canDelete() ? _c('div', {
          staticClass: "col-sm-3"
        }, [_c('delete-customer-modal', {
          ref: "deleteCustomerModal",
          on: {
            "confirmed": _vm.deleteConfirmed
          }
        }), _c('button', {
          staticClass: "btn btn-sm btn-block btn-outline-danger",
          attrs: {
            "id": "deleteButton"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$refs.deleteCustomerModal.show(_vm.value);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('controls.delete')) + " ")])], 1) : _vm._e()])])])]), _c('b-tab', {
          attrs: {
            "title": _vm.$t('customer.settings')
          }
        }, [_c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": _vm.$t('customer.secondary-branch'),
            "label-for": "secondary-location",
            "content-cols": "10",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var ariaDescribedby = _ref2.ariaDescribedby;
              return [_c('e-form-select', {
                attrs: {
                  "id": _vm.id + '-secondary-location',
                  "name": "secondary-location",
                  "placeholder": _vm.$t('customer.select-branch'),
                  "size": "sm"
                },
                on: {
                  "input": _vm.saveEntity
                },
                model: {
                  value: _vm.value.secondaryLocation,
                  callback: function callback($$v) {
                    _vm.$set(_vm.value, "secondaryLocation", $$v);
                  },
                  expression: "value.secondaryLocation"
                }
              }, [_vm.value.secondaryLocation != null ? _c('option', {
                attrs: {
                  "value": ""
                }
              }, [_vm._v(_vm._s(_vm.$t('customer.remove-secondary-branch')))]) : _vm._e(), _vm._l(_vm.allLocations, function (location, idx) {
                return _c('option', {
                  key: idx,
                  domProps: {
                    "value": location.abbreviation
                  }
                }, [_vm._v(_vm._s(location.name) + " ")]);
              })], 2)];
            }
          }], null, true)
        }), _c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": _vm.$t('customer.waiver-customer'),
            "label-for": "waiverCustomer",
            "content-cols": "10",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var ariaDescribedby = _ref3.ariaDescribedby;
              return [_c('validation-provider', [_c('b-form-checkbox', {
                attrs: {
                  "switch": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.saveSetting(_vm.waiverCustomer);
                  }
                },
                model: {
                  value: _vm.waiverCustomer.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.waiverCustomer, "value", $$v);
                  },
                  expression: "waiverCustomer.value"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": _vm.$t('customer.invoicing-bulkiness'),
            "label-for": "invoicingBulkiness",
            "content-cols": "10",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var ariaDescribedby = _ref4.ariaDescribedby;
              return [_c('validation-provider', [_c('b-form-radio-group', {
                attrs: {
                  "name": "invoicingBulkiness",
                  "aria-describedby": ariaDescribedby,
                  "options": _vm.invoicingBulkinessModes
                },
                on: {
                  "change": function change($event) {
                    return _vm.saveSetting(_vm.invoicingBulkiness);
                  }
                },
                model: {
                  value: _vm.invoicingBulkiness.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.invoicingBulkiness, "value", $$v);
                  },
                  expression: "invoicingBulkiness.value"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": _vm.$t('customer.additionalPaletteSwapCountries'),
            "label-for": "additionalPaletteSwapCountries",
            "content-cols": "3",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var ariaDescribedby = _ref5.ariaDescribedby;
              return [_c('b-input-group', {
                attrs: {
                  "size": "sm"
                }
              }, [_c('country-select', {
                attrs: {
                  "name": "additionalPaletteSwapCountries",
                  "multiple": "",
                  "placeholder": _vm.$t('address.country'),
                  "filter": function filter(c) {
                    return !['DE', 'AT', 'BE', 'NL', 'LU', 'CH'].includes(c.alpha2Code);
                  },
                  "validate": false
                },
                on: {
                  "input": function input($event) {
                    var _vm$additionalPalette;

                    (_vm$additionalPalette = _vm.additionalPaletteSwapCountries.value) === null || _vm$additionalPalette === void 0 ? void 0 : _vm$additionalPalette.sort();

                    _vm.saveSetting(_vm.additionalPaletteSwapCountries);
                  }
                },
                model: {
                  value: _vm.additionalPaletteSwapCountries.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.additionalPaletteSwapCountries, "value", $$v);
                  },
                  expression: "additionalPaletteSwapCountries.value"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": _vm.$t('customer.manual-invoice-recipients'),
            "label-for": "manualInvoiceRecipients",
            "content-cols": "10",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var ariaDescribedby = _ref6.ariaDescribedby;
              return [_c('validation-provider', [_c('b-form-checkbox', {
                attrs: {
                  "switch": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.saveSetting(_vm.manualInvoiceRecipients);
                  }
                },
                model: {
                  value: _vm.manualInvoiceRecipients.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.manualInvoiceRecipients, "value", $$v);
                  },
                  expression: "manualInvoiceRecipients.value"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": _vm.$t('customer.optional-fields'),
            "label-for": "optionalFields",
            "content-cols": "10",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var ariaDescribedby = _ref7.ariaDescribedby;
              return [_c('validation-provider', [_c('b-form-checkbox-group', {
                attrs: {
                  "name": "optionalFields",
                  "aria-describedby": ariaDescribedby,
                  "options": _vm.optionalFieldNames
                },
                on: {
                  "change": function change($event) {
                    return _vm.saveSetting(_vm.optionalFields);
                  }
                },
                model: {
                  value: _vm.optionalFields.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.optionalFields, "value", $$v);
                  },
                  expression: "optionalFields.value"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": _vm.$t('customer.mandatory-fields'),
            "label-for": "mandatoryFields",
            "content-cols": "10",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var ariaDescribedby = _ref8.ariaDescribedby;
              return [_c('validation-provider', [_c('b-form-checkbox-group', {
                attrs: {
                  "name": "mandatoryFields",
                  "aria-describedby": ariaDescribedby,
                  "options": _vm.mandatoryFieldNames
                },
                on: {
                  "change": function change($event) {
                    return _vm.saveSetting(_vm.mandatoryFields);
                  }
                },
                model: {
                  value: _vm.mandatoryFields.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.mandatoryFields, "value", $$v);
                  },
                  expression: "mandatoryFields.value"
                }
              })], 1)];
            }
          }], null, true)
        }), _c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": _vm.$t('customer.sftp-enabled'),
            "label-for": "sftpEnabled",
            "content-cols": "10",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var ariaDescribedby = _ref9.ariaDescribedby;
              return [_c('validation-provider', [_c('b-form-checkbox', {
                attrs: {
                  "name": "sftpEnabled",
                  "switch": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.saveSetting(_vm.sftpEnabled);
                  }
                },
                model: {
                  value: _vm.sftpEnabled.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.sftpEnabled, "value", $$v);
                  },
                  expression: "sftpEnabled.value"
                }
              })], 1)];
            }
          }], null, true)
        }), _vm.sftpEnabled.value ? _c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": "SFTP-Passwort",
            "label-for": "sftpPassword",
            "content-cols": "3",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var ariaDescribedby = _ref10.ariaDescribedby;
              return [_c('b-input-group', {
                attrs: {
                  "size": "sm"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "name": "sftpPassword",
                  "disabled": ""
                },
                model: {
                  value: _vm.sftpPassword.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.sftpPassword, "value", $$v);
                  },
                  expression: "sftpPassword.value"
                }
              }), _c('b-input-group-append', [_c('b-button', {
                attrs: {
                  "variant": "outline-danger"
                },
                on: {
                  "click": function click($event) {
                    $event.preventDefault();
                    return _vm.generatePassword();
                  }
                }
              }, [_c('span', [_vm._v("Generieren und speichern")])])], 1)], 1)];
            }
          }], null, true)
        }) : _vm._e(), _vm.sftpEnabled.value ? _c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": "SFTP automatische Beauftragung",
            "label-for": "sftpAutoCommissioning",
            "content-cols": "3",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var ariaDescribedby = _ref11.ariaDescribedby;
              return [_c('validation-provider', [_c('b-form-checkbox', {
                attrs: {
                  "name": "sftpAutoCommissioning",
                  "switch": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.saveSetting(_vm.sftpAutoCommissioning);
                  }
                },
                model: {
                  value: _vm.sftpAutoCommissioning.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.sftpAutoCommissioning, "value", $$v);
                  },
                  expression: "sftpAutoCommissioning.value"
                }
              })], 1)];
            }
          }], null, true)
        }) : _vm._e(), _vm.sftpEnabled.value ? _c('b-form-group', {
          staticClass: "pt-2",
          attrs: {
            "label": "SFTP Standardlieferhinweise berücksichtigen",
            "label-for": "sftpEnableDefaultNotificationCodes",
            "content-cols": "3",
            "label-cols": "2",
            "label-class": "pt-1"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var ariaDescribedby = _ref12.ariaDescribedby;
              return [_c('validation-provider', [_c('b-form-checkbox', {
                attrs: {
                  "name": "sftpEnableDefaultNotificationCodes",
                  "switch": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.saveSetting(_vm.sftpEnableDefaultNotificationCodes);
                  }
                },
                model: {
                  value: _vm.sftpEnableDefaultNotificationCodes.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.sftpEnableDefaultNotificationCodes, "value", $$v);
                  },
                  expression: "sftpEnableDefaultNotificationCodes.value"
                }
              })], 1)];
            }
          }], null, true)
        }) : _vm._e()], 1), _c('b-tab', {
          attrs: {
            "title": _vm.$t('customer.invoice-recipients'),
            "lazy": "",
            "disabled": !((_vm$value = _vm.value) !== null && _vm$value !== void 0 && (_vm$value$_links = _vm$value._links) !== null && _vm$value$_links !== void 0 && (_vm$value$_links$invo = _vm$value$_links.invoiceRecipients) !== null && _vm$value$_links$invo !== void 0 && _vm$value$_links$invo.href)
          }
        }, [_c('b-navbar', [_c('b-navbar-nav', {
          staticClass: "ml-auto"
        }, [_c('b-nav-form', [!!((_vm$invoiceRecipients = _vm.invoiceRecipients) !== null && _vm$invoiceRecipients !== void 0 && (_vm$invoiceRecipients2 = _vm$invoiceRecipients.links) !== null && _vm$invoiceRecipients2 !== void 0 && (_vm$invoiceRecipients3 = _vm$invoiceRecipients2.create) !== null && _vm$invoiceRecipients3 !== void 0 && _vm$invoiceRecipients3.href) ? _c('b-input-group', {
          attrs: {
            "size": "sm"
          }
        }, [_c('invoice-recipient-modal', {
          ref: "invoiceRecipientModal",
          attrs: {
            "id": _vm.value.customerId + '-invoiceRecipient',
            "customer-id": _vm.value.customerId,
            "creation-link": (_vm$invoiceRecipients4 = _vm.invoiceRecipients) === null || _vm$invoiceRecipients4 === void 0 ? void 0 : (_vm$invoiceRecipients5 = _vm$invoiceRecipients4.links) === null || _vm$invoiceRecipients5 === void 0 ? void 0 : (_vm$invoiceRecipients6 = _vm$invoiceRecipients5.create) === null || _vm$invoiceRecipients6 === void 0 ? void 0 : _vm$invoiceRecipients6.href
          },
          on: {
            "save": function save($event) {
              _vm.refreshInvoiceRecipients++;
            }
          }
        }), _c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.invoiceRecipientModal.show();
            }
          }
        }, [_c('b-icon-plus'), _vm._v(" " + _vm._s(_vm.$t('controls.add')))], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('div', {
          staticClass: "p-1 pl-0 pr-0 row"
        }, [_c('div', {
          staticClass: "col-1 clip-text"
        }, [_c('strong', [_vm._v(_vm._s(_vm.$t('invoiceRecipient.isDefault')))])]), _c('div', {
          staticClass: "col-1 clip-text"
        }, [_c('strong', [_vm._v(_vm._s(_vm.$t('address.customerId')))])]), _c('div', {
          staticClass: "col-3 clip-text"
        }, [_c('strong', [_vm._v(_vm._s(_vm.$t('address.name')))])]), _c('div', {
          staticClass: "col-6"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-5 clip-text"
        }, [_c('strong', [_vm._v(_vm._s(_vm.$t('address.street')))])]), _c('div', {
          staticClass: "col-1 clip-text"
        }, [_c('strong', [_vm._v(_vm._s(_vm.$t('address.country')))])]), _c('div', {
          staticClass: "col-2 clip-text"
        }, [_c('strong', [_vm._v(_vm._s(_vm.$t('address.zipCode')))])]), _c('div', {
          staticClass: "col-4 clip-text"
        }, [_c('strong', [_vm._v(_vm._s(_vm.$t('address.city')))])])])])]), _c('div', {
          staticClass: "scroll-embedded-header-line"
        }), _c('scrollbar', {
          staticClass: "scroll-embedded-content",
          style: {
            'height': '300px',
            'width': '100%'
          },
          attrs: {
            "settings": _vm.scrollSettings
          }
        }, _vm._l((_vm$invoiceRecipients7 = _vm.invoiceRecipients) === null || _vm$invoiceRecipients7 === void 0 ? void 0 : _vm$invoiceRecipients7.items, function (recipient, index) {
          var _recipient$_links, _recipient$_links$mod, _recipient$_links2, _recipient$_links2$mo;

          return _c('div', {
            staticClass: "p-1 pl-0 pr-0 row"
          }, [_c('div', {
            staticClass: "col-1 clip-text text-center"
          }, [!!recipient.isDefault ? _c('span', [_c('b-icon-check-circle')], 1) : _c('span')]), _c('div', {
            staticClass: "col-1 clip-text"
          }, [_c('span', [_vm._v(_vm._s(recipient.customerId))])]), _c('div', {
            staticClass: "col-3 clip-text"
          }, [_c('span', [_vm._v(_vm._s(recipient.name))])]), _c('div', {
            staticClass: "col-6"
          }, [_c('div', {
            staticClass: "row"
          }, [_c('div', {
            staticClass: "col-5 clip-text"
          }, [_c('span', [_vm._v(_vm._s(recipient.street))])]), _c('div', {
            staticClass: "col-1 clip-text"
          }, [_c('span', [_vm._v(_vm._s(recipient.country))])]), _c('div', {
            staticClass: "col-2 clip-text"
          }, [_c('span', [_vm._v(_vm._s(recipient.zipCode))])]), _c('div', {
            staticClass: "col-4 clip-text"
          }, [_c('span', [_vm._v(_vm._s(recipient.city))])])])]), _c('div', {
            staticClass: "col-1"
          }, [recipient !== null && recipient !== void 0 && (_recipient$_links = recipient._links) !== null && _recipient$_links !== void 0 && (_recipient$_links$mod = _recipient$_links.modify) !== null && _recipient$_links$mod !== void 0 && _recipient$_links$mod.href ? _c('b-dropdown', {
            attrs: {
              "text": _vm.$t('controls.actions'),
              "right": "",
              "size": "sm",
              "variant": "primary"
            }
          }, [recipient !== null && recipient !== void 0 && (_recipient$_links2 = recipient._links) !== null && _recipient$_links2 !== void 0 && (_recipient$_links2$mo = _recipient$_links2.modify) !== null && _recipient$_links2$mo !== void 0 && _recipient$_links2$mo.href ? _c('b-dropdown-item-button', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.$refs.invoiceRecipientModal.show(recipient);
              }
            }
          }, [_c('b-icon-pencil-square'), _vm._v(" " + _vm._s(_vm.$t('controls.edit')) + " ")], 1) : _vm._e()], 1) : _vm._e()], 1)]);
        }), 0)], 1), _c('b-tab', {
          staticClass: "tab-content",
          attrs: {
            "title": _vm.$t('customer.addresses') + (_vm.value.addresses && _vm.value.addresses.page ? ' (' + _vm.value.addresses.page.totalElements + ')' : ''),
            "lazy": ""
          },
          on: {
            "click": function click($event) {
              return _vm.findAddresses();
            }
          }
        }, [_c('div', {
          staticClass: "p-1 pl-0 pr-0 row"
        }, [_c('div', {
          staticClass: "col-md-3"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-4"
        }, [_c('b', [_vm._v(_vm._s(_vm.$t('address.defaultSenderAndConsignee.short')))])]), _c('div', {
          staticClass: "col-8"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.name')))])])])])]), _c('div', {
          staticClass: "col-md-1"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.customerId')))])])]), _c('div', {
          staticClass: "col-md-3"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.street')))])])]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.city')))])])])])]), _c('div', {
          staticClass: "col-md-3"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.email')))])])]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.$t('address.phone')))])])])])]), _c('div', {
          staticClass: "col-md-1"
        })]), _c('div', {
          staticClass: "scroll-embedded-header-line"
        }), _vm.value.addresses && _vm.value.addresses.items.length > 0 ? _c('scrollbar', {
          staticClass: "scroll-embedded-content",
          style: {
            'height': '300px',
            'width': '100%'
          },
          attrs: {
            "settings": _vm.scrollSettings
          },
          on: {
            "ps-y-reach-end": function psYReachEnd($event) {
              return _vm.nextPageAddresses();
            }
          }
        }, [_c('address-modal', {
          ref: "addressModal",
          attrs: {
            "id": _vm.value.customerId + '-address',
            "is-admin": true,
            "customer-id": _vm.value.customerId,
            "creation-link": (_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$addresses = _vm$value2.addresses) === null || _vm$value2$addresses === void 0 ? void 0 : (_vm$value2$addresses$ = _vm$value2$addresses.links) === null || _vm$value2$addresses$ === void 0 ? void 0 : (_vm$value2$addresses$2 = _vm$value2$addresses$.create) === null || _vm$value2$addresses$2 === void 0 ? void 0 : _vm$value2$addresses$2.href
          },
          on: {
            "save": _vm.findAddresses,
            "delete": _vm.findAddresses
          }
        }), _c('b-navbar', {
          staticClass: "col-md-5 offset-md-7",
          attrs: {
            "sticky": "",
            "variant": "secondary"
          }
        }, [_c('b-navbar-nav', {
          staticClass: "ml-auto"
        }, [_c('b-nav-form', [!!((_vm$value3 = _vm.value) !== null && _vm$value3 !== void 0 && (_vm$value3$addresses = _vm$value3.addresses) !== null && _vm$value3$addresses !== void 0 && (_vm$value3$addresses$ = _vm$value3$addresses.links) !== null && _vm$value3$addresses$ !== void 0 && (_vm$value3$addresses$2 = _vm$value3$addresses$.create) !== null && _vm$value3$addresses$2 !== void 0 && _vm$value3$addresses$2.href) ? _c('b-input-group', {
          attrs: {
            "size": "sm"
          }
        }, [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "size": "sm",
            "variant": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.addressModal.show();
            }
          }
        }, [_c('b-icon-plus'), _vm._v(" " + _vm._s(_vm.$t('controls.add')))], 1)], 1) : _vm._e(), _c('b-input-group', {
          staticStyle: {
            "width": "150px"
          },
          attrs: {
            "size": "sm"
          }
        }, [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('b-icon-search')], 1), _c('b-form-input', {
          directives: [{
            name: "debounce",
            rawName: "v-debounce:1000ms",
            value: _vm.findAddresses,
            expression: "findAddresses",
            arg: "1000ms"
          }],
          staticClass: "search-input",
          attrs: {
            "size": "sm",
            "type": "text"
          },
          model: {
            value: _vm.value.searchTextAddress,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "searchTextAddress", $$v);
            },
            expression: "value.searchTextAddress"
          }
        }), _vm.value.searchTextAddress ? _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('b-icon-x', {
          on: {
            "click": function click($event) {
              _vm.value.searchTextAddress = null;

              _vm.findAddresses();
            }
          }
        })], 1) : _vm._e()], 1), _c('b-input-group', {
          attrs: {
            "size": "sm"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "size": "sm",
            "variant": "secondary"
          },
          on: {
            "click": _vm.exportAddresses
          }
        }, [_c('b-icon-download'), _vm._v(" " + _vm._s(_vm.$t('export')))], 1)], 1), _c('b-input-group', {
          attrs: {
            "size": "sm"
          }
        }, [_c('import-address-data-modal', {
          ref: "importAddressDataModal",
          attrs: {
            "id": _vm.value.customerId
          },
          on: {
            "confirmed": _vm.importAddresses
          }
        }), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "size": "sm",
            "variant": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.importAddressDataModal.show('text/vcard,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
            }
          }
        }, [_c('b-icon-upload'), _vm._v(" " + _vm._s(_vm.$t('import')))], 1)], 1), !!((_vm$value4 = _vm.value) !== null && _vm$value4 !== void 0 && (_vm$value4$addresses = _vm$value4.addresses) !== null && _vm$value4$addresses !== void 0 && (_vm$value4$addresses$ = _vm$value4$addresses.links) !== null && _vm$value4$addresses$ !== void 0 && (_vm$value4$addresses$2 = _vm$value4$addresses$.deleteAll) !== null && _vm$value4$addresses$2 !== void 0 && _vm$value4$addresses$2.href) ? _c('b-input-group', {
          attrs: {
            "size": "sm"
          }
        }, [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "size": "sm",
            "variant": "secondary"
          },
          on: {
            "click": _vm.deleteAllAddresses
          }
        }, [_c('b-icon-trash'), _vm._v(" " + _vm._s(_vm.$t('controls.delete-all')))], 1)], 1) : _vm._e()], 1)], 1)], 1), _vm._l(_vm.value.addresses.items, function (address, index) {
          var _address$_links, _address$_links$modif, _address$_links2, _address$_links2$modi, _address$_links3, _address$_links3$dele;

          return _c('div', {
            staticClass: "p-1 pl-0 pr-0 row"
          }, [_c('div', {
            staticClass: "col-md-3"
          }, [_c('div', {
            staticClass: "row"
          }, [_c('div', {
            staticClass: "col-4"
          }, [_c('b-checkbox', {
            staticClass: "custom-control-inline",
            attrs: {
              "checked": address.isDefaultSenderAddress
            },
            on: {
              "change": function change($event) {
                return _vm.setDefaultSenderAddress(address, $event);
              }
            }
          }), _c('b-checkbox', {
            staticClass: "custom-control-inline",
            attrs: {
              "checked": address.isDefaultConsigneeAddress
            },
            on: {
              "change": function change($event) {
                return _vm.setDefaultConsigneeAddress(address, $event);
              }
            }
          })], 1), _c('div', {
            staticClass: "col-8 text-truncate"
          }, [_c('span', [_vm._v(_vm._s(address.name))]), address.additionalName ? _c('br') : _vm._e(), _c('span', [_vm._v(_vm._s(address.additionalName))])])])]), _c('div', {
            staticClass: "col-md-1 text-truncate"
          }, [address.emonsCustomerId ? _c('span', [_c('b-icon-truck', {
            attrs: {
              "title": "Emons"
            }
          }), _vm._v(" " + _vm._s(address.emonsCustomerId))], 1) : _vm._e(), !!address.emonsCustomerId && !!address.customerId ? _c('br') : _vm._e(), !!address.customerId ? _c('span', [!!address.emonsCustomerId && !!address.customerId ? _c('b-icon-person') : _vm._e(), _vm._v(" " + _vm._s(address.customerId))], 1) : _vm._e()]), _c('div', {
            staticClass: "col-md-3"
          }, [_c('div', {
            staticClass: "row"
          }, [_c('div', {
            staticClass: "col-md-6 text-truncate"
          }, [_c('span', [_vm._v(_vm._s(address.street))])]), _c('div', {
            staticClass: "col-md-6 text-truncate"
          }, [_c('span', [_vm._v(_vm._s(address.country) + "-" + _vm._s(address.zipCode) + " " + _vm._s(address.city))])])])]), _c('div', {
            staticClass: "col-md-3"
          }, [_c('div', {
            staticClass: "row"
          }, [_c('div', {
            staticClass: "col-md-6 text-truncate"
          }, [_c('span', [_vm._v(_vm._s(address.email))])]), _c('div', {
            staticClass: "col-md-6 text-truncate"
          }, [!!address.phone ? _c('span', [_c('b-icon-telephone'), _vm._v(" " + _vm._s(address.phone) + " ")], 1) : _vm._e(), _c('br'), !!address.mobile ? _c('span', [_c('b-icon-phone'), _vm._v(" " + _vm._s(address.mobile))], 1) : _vm._e()])])]), _c('div', {
            staticClass: "col-md-1"
          }, [address !== null && address !== void 0 && (_address$_links = address._links) !== null && _address$_links !== void 0 && (_address$_links$modif = _address$_links.modify) !== null && _address$_links$modif !== void 0 && _address$_links$modif.href ? _c('b-dropdown', {
            attrs: {
              "text": _vm.$t('controls.actions'),
              "right": "",
              "size": "sm",
              "variant": "primary"
            }
          }, [address !== null && address !== void 0 && (_address$_links2 = address._links) !== null && _address$_links2 !== void 0 && (_address$_links2$modi = _address$_links2.modify) !== null && _address$_links2$modi !== void 0 && _address$_links2$modi.href ? _c('b-dropdown-item-button', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.$refs.addressModal.show(address);
              }
            }
          }, [_c('b-icon-pencil-square'), _vm._v(" " + _vm._s(_vm.$t('controls.edit')) + " ")], 1) : _vm._e(), address !== null && address !== void 0 && (_address$_links3 = address._links) !== null && _address$_links3 !== void 0 && (_address$_links3$dele = _address$_links3.delete) !== null && _address$_links3$dele !== void 0 && _address$_links3$dele.href ? _c('b-dropdown-item-button', {
            attrs: {
              "href": "#",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.confirmDelete(address);
              }
            }
          }, [_c('b-icon-trash'), _vm._v(" " + _vm._s(_vm.$t('controls.delete')) + " ")], 1) : _vm._e()], 1) : _vm._e()], 1)]);
        })], 2) : _vm._e()], 1), _c('b-tab', {
          attrs: {
            "title": _vm.$t('customer.members'),
            "lazy": ""
          }
        }, [_c('customer-add-user-modal', {
          ref: "addUserModal",
          attrs: {
            "id": _vm.id,
            "customer-id": _vm.value.customerId
          },
          on: {
            "confirmed": function confirmed($event) {
              return _vm.addUser($event);
            }
          }
        }), _c('b-navbar', [(_vm$value5 = _vm.value) !== null && _vm$value5 !== void 0 && (_vm$value5$_links = _vm$value5._links) !== null && _vm$value5$_links !== void 0 && (_vm$value5$_links$reg = _vm$value5$_links.registerUser) !== null && _vm$value5$_links$reg !== void 0 && _vm$value5$_links$reg.href ? _c('b-navbar-nav', [_c('b-link', {
          staticClass: "a",
          attrs: {
            "href": (_vm$value6 = _vm.value) === null || _vm$value6 === void 0 ? void 0 : (_vm$value6$_links = _vm$value6._links) === null || _vm$value6$_links === void 0 ? void 0 : (_vm$value6$_links$reg = _vm$value6$_links.registerUser) === null || _vm$value6$_links$reg === void 0 ? void 0 : _vm$value6$_links$reg.href,
            "target": "_blank"
          }
        }, [_c('b-icon-link', {
          attrs: {
            "font-scale": "1.0"
          }
        }), _vm._v(" Benutzer-Registrierung ")], 1)], 1) : _vm._e(), _c('b-navbar-nav', {
          staticClass: "ml-auto"
        }, [_c('b-input-group', {
          attrs: {
            "size": "sm"
          }
        }, [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.addUserModal.show();
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "plus"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('customer.add-user')) + " ")], 1)], 1)], 1)], 1), _c('customer-members-view', {
          ref: "members",
          attrs: {
            "customer": _vm.value
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }