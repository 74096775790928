<template>
  <validation-observer :vid="'orderObserver-' + orderId" ref="observer"
                       v-slot="{ errors, invalid, changed, dirty }" slim>
    <div>
      <b-tabs fill active-nav-item-class="font-weight-bold text-light bg-primary"  nav-class="border bg-primary-light">
        <b-tab :title="$t('orders.overview')" title-link-class="text-light" active>
          <div class="row mt-2">
            <div class="col-md-8">
              <div class="row">
                <div class="col-6">
                  <order-sender-view :id="orderId" :editable="editMode" :default-country="defaultCountry"
                                     :is-belog="order?.consignee?.emonsCustomerId?.length > 0"
                                     v-model="order.sender"
                                     @input="senderSelected($event)"
                                     @cleared="$refs.senderDeliveryNotes.clear()">
                    <template v-slot:default>
                      <validation-provider :vid="orderId + '-sender.deliveryNotes'">
                        <delivery-notes-view :id="orderId" :editable="editMode" ref="senderDeliveryNotes"
                                             :title="$t('shipment.sender.deliveryNotes')"
                                             v-model="order.deliveryNotes"
                                             :add-defaults="order.state == 'NEW'"
                                             :sender="order.sender"
                                             :product="order.product"
                                             :filter="n => n.identifier.startsWith('6')"
                        />
                      </validation-provider>
                    </template>
                  </order-sender-view>
                </div>
                <div class="col-6">
                  <order-consignee-view :id="orderId" :editable="editMode" :default-country="defaultCountry"
                                        v-model="order.consignee"
                                        @cleared="$refs.consigneeDeliveryNotes.clear()">
                    <template v-slot:default>
                      <validation-provider :vid="orderId + '-consignee.deliveryNotes'">
                        <delivery-notes-view :id="orderId" :editable="editMode" ref="consigneeDeliveryNotes"
                                             :title="$t('shipment.consignee.deliveryNotes')"
                                             v-model="order.deliveryNotes"
                                             :add-defaults="order.state == 'NEW'"
                                             :consignee="order.consignee"
                                             :product="order.product"
                                             :filter="n => !n.identifier.startsWith('6')"
                        />
                      </validation-provider>
                    </template>
                  </order-consignee-view>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <order-invoice-recipient-view :id="orderId" :editable="editMode"
                                                v-model="order.invoiceRecipient" :default-recipient="defaultInvoiceRecipient"/>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-12">
                  <order-characteristics-view :id="orderId" :editable="editMode" :value="order"
                                              :shipping-date-offset="shippingDateOffset"
                                              :waiver-customer="waiverCustomer"
                                              :required-fields="requiredFields"
                                              :customer-currency="customerCurrency"
                                              :branch-currency="branchCurrency"
                                              :supported-currencies="supportedCurrencies"
                                              :default-term-national="defaultIncoTermNational"
                                              :default-term-international="defaultIncoTermInternational"
                                              :shipping-time-from="shippingTimeFrom"
                                              :shipping-time-from-fr="shippingTimeFromFr"
                                              :shipping-time-until="shippingTimeUntil"
                                              :shipping-time-until-fr="shippingTimeUntilFr"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <order-product-view :id="orderId" :editable="editMode" :value="order"/>
                </div>
              </div>

            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('orders.cargo-list')" title-link-class="text-light">
          <div class="row mt-2">
            <div class="col-12">
              <order-cargo-list :id="orderId"
                                :editable="editMode"
                                :required-fields="requiredFields"
                                :optional-fields="optionalFields"
                                :value="order.cargoList"
                                :consignee-country="order?.consignee?.country"
                                :additional-palette-swap-countries="additionalPaletteSwapCountries"
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 pt-4">
              <order-shipment-info :id="orderId"
                                   :editable="editMode"
                                   :optional-fields="optionalFields"
                                   :mandatory-fields="mandatoryFields"
                                   :value="order.cargoList"
              />
            </div>
          </div>

        </b-tab>
        <b-tab v-if="order?._links?.auditLog" :title="$t('auditlog.title')" title-link-class="text-light" lazy>
          <div class="mt-2">
            <audit-log :value="order"
                       @enableSpinner="$emit('enableSpinner')" @disableSpinner="$emit('disableSpinner')" />
          </div>
        </b-tab>
      </b-tabs>
      <div class="row mt-4">
        <div class="col-9 offset-3">
          <div class="form-group row justify-content-end" v-if="!editMode">
            <div class="col-sm-2" v-if="order?._links?.pickUpOrder">
              <shipment-pick-up-order-modal :id="orderId + '-pick-up-order'" :shipment="order" ref="shipmentPickUpOrderModal" />
              <button :id="'pick-up-order-' + orderId" class="btn btn-sm btn-block btn-outline-secondary" @click="$refs.shipmentPickUpOrderModal.show()">
                {{ $t('shipment.pick-up-order') }}</button>
            </div>
            <div class="col-sm-2" v-if="order?._links?.inquiry">
              <shipment-inquiry-modal :id="orderId + '-inquiry'" :shipment="order" ref="shipmentInquiryModal" />
              <button :id="'inquiry-' + orderId" class="btn btn-sm btn-block btn-outline-secondary" @click="$refs.shipmentInquiryModal.show()">
                {{ $t('shipment.inquiry') }}</button>
            </div>
            <div class="col-sm-2" v-if="order?._links?.announce?.href">
              <announce-shipment-modal :id="orderId + '-announceShipment'" ref="announceShipmentModal" @confirmed="announceOrder" />
              <button :id="'voravis-' + orderId" class="btn btn-sm btn-block btn-outline-secondary" @click="$refs.announceShipmentModal.show([order])" :disabled="axiosSaveInProgress">{{ $t('shipment.shipping-notice') }}</button>
            </div>
            <div class="col-sm-2"  v-if="order?._links?.commission?.href">
              <button :id="'release-' + orderId" class="btn btn-sm btn-block btn-outline-secondary" @click="finalizeOrder" :disabled="axiosSaveInProgress">{{ $t('shipment.commission') }}</button>
            </div>
            <div class="col-sm-2"  v-if="order?._links?.modify?.href">
              <button :id="'edit-' + orderId" type="submit" class="btn btn-sm btn-block btn-outline-primary" @click="enableEditMode">{{ $t('controls.edit') }}</button>
            </div>
            <div class="col-sm-2"  v-if="order?._links?.delete?.href">
              <button :id="'delete-' + orderId" class="btn btn-sm btn-block btn-outline-danger" @click="confirmDeleteOrder">{{ $t('controls.delete') }}</button>
            </div>
            <div class="col-sm-2" v-else-if="order?._links?.modify?.href">
              <button :id="'delete-' + orderId" class="btn btn-sm btn-block btn-outline-danger" @click="confirmCancelOrder" :disabled="axiosSaveInProgress">{{ $t('shipment.cancel') }}</button>
            </div>
          </div>
          <div class="form-group row justify-content-end" v-else>
            <div class="col-sm-2" v-if="order?._links?.inquiry">
              <shipment-inquiry-modal :id="orderId + '-inquiry'" :shipment="order" ref="shipmentInquiryModal" />
              <button :id="'inquiry-' + orderId" class="btn btn-sm btn-block btn-outline-secondary" @click="$refs.shipmentInquiryModal.show()">
                {{ $t('shipment.inquiry') }}</button>
            </div>
            <div class="col-sm-2">
              <button v-if="!isNew" :id="'abort-' + orderId" type="submit" class="btn btn-sm btn-block btn-outline-secondary" @click="abortEditMode">{{ $t('controls.abort') }}</button>
            </div>
            <div class="col-sm-2">
              <div v-if="invalid"
                   v-b-tooltip.hover :title="$t('form.fillout-mandatory-fields')">
                <button :id="'save-' + orderId" type="button"
                        class="btn btn-sm btn-block btn-outline-secondary"
                        :disabled="axiosSaveInProgress || !changed" @click="showErrors">
                  {{ $t('controls.save') }}
                </button>
              </div>
              <button v-else-if="isNew || order?._links?.modify?.href"
                      :id="'save-' + orderId"
                      type="submit" class="btn btn-sm btn-block btn-outline-primary"
                      :disabled="axiosSaveInProgress || !changed" @click="saveOrder">
                {{ $t('controls.save') }}
              </button>
            </div>
            <div class="col-sm-2" v-if="isNew || order?._links?.delete?.href">
              <button :id="'delete-' + orderId" class="btn btn-sm btn-block btn-outline-danger" @click="confirmDeleteOrder">{{ $t('controls.delete') }}</button>
            </div>
            <div class="col-sm-2" v-else-if="order?._links?.modify?.href">
              <button :id="'delete-' + orderId" class="btn btn-sm btn-block btn-outline-danger" @click="confirmCancelOrder">{{ $t('shipment.cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "@emons/emons-vue"
import ShipmentService from "@/services/shipmentService";
import OrderSenderView from "@/components/OrderSenderView";
import OrderConsigneeView from "@/components/OrderConsigneeView";
import OrderInvoiceRecipientView from "@/components/OrderInvoiceRecipientView";
import OrderCharacteristicsView from "@/components/OrderCharacteristicsView";
import OrderProductView from "@/components/OrderProductView";
import DeliveryNotesView from "@/components/DeliveryNotesView";
import OrderShipmentInfo from "@/components/OrderShipmentInfo";
import OrderCargoList from "@/components/OrderCargoList";
import PrinterTypeModal from "@/components/modals/PrinterTypeModal";
import AnnounceShipmentModal from "@/components/modals/AnnounceShipmentModal";
import ShipmentInquiryModal from "@/components/modals/ShipmentInquiryModal";
import ShipmentPickUpOrderModal from "@/components/modals/ShipmentPickUpOrderModal";
import SettingsService from "@/services/settings.service"
import AxiosServiceMixin from "@/components/mixins/AxiosServiceMixin";
import CurrencyService from "@/services/currency.service";
import InvoiceRecipientAddressService from "@/services/invoiceRecipientAddress.service";
import AuditLog from "@/components/auditlog/AuditLog.vue";

export default {
  name: "OrderDetails",
  mixins: [AxiosServiceMixin],
  components: {
    AuditLog,
    AnnounceShipmentModal,
    ShipmentInquiryModal,
    ShipmentPickUpOrderModal,
    PrinterTypeModal,
    OrderCargoList,
    OrderShipmentInfo,
    DeliveryNotesView,
    OrderProductView,
    OrderCharacteristicsView,
    OrderInvoiceRecipientView, OrderConsigneeView, OrderSenderView, ValidationObserver, ValidationProvider
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    branchCurrency: {
      type: String,
      required: true
    },
    defaultCountry: {
      type: String,
      default: 'DE'
    }
  },
  data() {
    return {
      editable: false,
      orderBackup: null,
      settings: null,
    }
  },
  mounted() {
    SettingsService.findAll().then(response => this.settings = response.data)
  },
  asyncComputed: {
    async supportedCurrencies() {
      return await CurrencyService.getSupportedCurrencies()
    },
    async defaultInvoiceRecipient() {
      const emonsCustomerId = this.order.emonsCustomerId
      return await InvoiceRecipientAddressService.getDefault(emonsCustomerId)
    }
  },
  computed: {
    additionalPaletteSwapCountries: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'ADDITIONAL_PALETTE_SWAP_COUNTRIES')
        if (setting) {
          return setting.value
        }
      }
      return []
    },
    shippingDateOffset: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'SHIPPING_DATE_OFFSET')
        if (setting) {
          return parseInt(setting.value)
        }
      }
      return 0
    },
    waiverCustomer: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'WAIVER_CUSTOMER')
        if (setting) {
          return setting.value
        }
      }
      return false
    },
    optionalFields: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'OPTIONAL_FIELDS')
        if (setting) {
          return setting.value
        }
      }
      return []
    },
    mandatoryFields: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'ADMIN_MANDATORY_FIELDS')
        if (setting) {
          return setting.value
        }
      }
      return []
    },
    requiredFields: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'REQUIRED_FIELDS')
        if (setting) {
          return setting.value
        }
      }
      return []
    },
    customerCurrency: function() {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'CURRENCY_DEFAULT')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    defaultIncoTermNational: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'INCOTERMS_NATIONAL')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    defaultIncoTermInternational: function () {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'INCOTERMS_INTERNATIONAL')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    shippingTimeFrom: function() {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'SHIPPING_TIME_FROM')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    shippingTimeUntil: function() {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'SHIPPING_TIME_UNTIL')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    shippingTimeFromFr: function() {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'SHIPPING_TIME_FROM_FR')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    shippingTimeUntilFr: function() {
      if (this.settings) {
        let setting = this.settings.find(setting => setting.name == 'SHIPPING_TIME_UNTIL_FR')
        if (setting) {
          return setting.value
        }
      }
      return ''
    },
    isNew: function () {
      return !(this.order && this.order._links && this.order._links.self)
    },
    orderId: function () {
      if (this.isNew) return '__unsaved__'
      return /[^/]*$/.exec(this?.order?._links?.self?.href)[0]
    },
    editMode: function () {
      if (this.isNew) {
        return true
      } else {
        return this.editable
      }
    }
  },
  methods: {
    senderSelected: function(sender) {
      this.$log('DEBUG', 'current sender', this.order.sender)
      if (!!sender.invoiceRecipient) {
        this.order.invoiceRecipient = {...sender.invoiceRecipient, _fromSender: true}
      } else if (this.order?.invoiceRecipient?._fromSender) {
        this.order.invoiceRecipient = null
      }
    },
    enableEditMode: function () {
      // item[0] auf changed setzen, damit wenn man eine Vorlage hinzufügt die Position 0 nicht überschrieben wird.
      if (this.order.cargoList && this.order.cargoList.items && this.order.cargoList.items.length > 0)
        this.order.cargoList.items[0]['changed'] = true

      this.editable = true
      this.orderBackup = JSON.parse(JSON.stringify(this.order))
      //this.order = JSON.parse(JSON.stringify(this.order))
      this.$refs.observer.reset()
      this.$nextTick(() => {
        this.$refs.observer.validate()

      })
    },
    abortEditMode: function () {
      this.editable = false
      this.order = this.orderBackup
      this.$refs.observer.reset()
    },
    translateId: function(id) {
      let prefix = this.orderId + "-"
      if (id.startsWith(prefix)) {
        return id.substring(prefix.length)
      }

      return id
    },
    showErrors: async function() {
      let ret = await this.$refs.observer.validateWithInfo()
      let realErrors = Object.keys(ret.errors).map(key => {
        let field = this.translateId(key)
        let value = ret.errors[key]
        return ret.errors[key].length > 0?{field: field, defaultMessage: value[0]}:null
      }).filter(o => o != null)
      this.$log('DEBUG', this.orderId, 'errors', ret, realErrors)

      this.$eventBus.$emit('frontend-error', {
        status: -400,
        error: "Die Sendung kann nicht gespeichert werden.",
        errors: realErrors
      })
    },
    saveOrder: function () {
      this.doSave(ShipmentService, this.order,
          () => {
            this.orderBackup = null
            this.editable = false
          },
          () => {},
          this.$refs['observer'],
          this.orderId + "-"
      )
    },
    announceOrder: async function (announcementData) {
      this.$log('DEBUG', 'order announcement confirmed', announcementData)
      try {
        const response = await this.doAxiosCall(async () => {
          return await ShipmentService.announceShipments(announcementData)
        })
        this.$emit('save', response[0])
      } catch(error) {
        this.$eventBus.$emit('backend-error', error)
      }
    },
    cancelOrder: async function () {
      try {
        const response = await this.doAxiosCall(async () => {
          this.order.state = 'CANCELLED'
          return await ShipmentService.save(this.order)
        })
        this.$emit('save', response.data)
      } catch(error) {
        this.$eventBus.$emit('backend-error', error)
      }
    },
    finalizeOrder: async function () {
      try {
        const response = await this.doAxiosCall(async () => {
          return await ShipmentService.setCommissioned(this.order)
        })
        if(!!response.data) {
          this.$emit('save', response.data)
        }
      } catch (error) {
        this.$eventBus.$emit('backend-error', error)
      }
    },
    confirmDeleteOrder: function () {
      this.$bvModal.msgBoxConfirm(
          this.$t('shipment.delete.confirm'),
          { title: this.$t('shipment.delete.confirm.short')}
      ).then(result => {
        if (result) this.deleteOrder()
      })
    },
    confirmCancelOrder: function () {
      this.$bvModal.msgBoxConfirm(
          this.$t('shipment.cancel.confirm'),
          { title:  this.$t('shipment.cancel.confirm.short')}
      ).then(result => {
        if (result) this.cancelOrder()
      })
    },
    deleteOrder: function () {
      ShipmentService.delete(this.order).then(this.$emit('delete'))
    },
    download: function () {
      ShipmentService.download([this.order])
    }
  }
}
</script>

<style>

.bg-primary-light {
  background-color: #007bff60;
}

.tabs >>> .nav-link {
  color: #000000;
}

.tabs >>> .nav-link.active {
  font-weight: bold;
}

.tabs >>> .nav-item.show .nav-link,
.tabs >>> .nav-link.active {
  background-color: transparent;
}

</style>