<template>
  <div class="card">
    <div class="card-header p-2"><label :for="id + '-orderNumber'"
                                        class="mb-0">{{ $t('orders.order-characteristics') }}</label></div>
    <div class="card-body p-2">
      <div class="form-row">
        <div class="col-6">
          <e-form-text-input :id="id + '-orderNumber'" name="shipment.orderNumber"
                             maxlength="35"
                             :rules="requiredFields.includes('shipment.orderNumber')?'required':''"
                             :placeholder="$t('order.number')"
                             :disabled="!editable"
                             v-model="value.orderNumber" />
        </div>
        <div class="col-6">
          <e-form-text-input :id="id + '-referenceNumber'" name="referenceNumber"
                             rules="integer"
                             maxlength="10"
                             :placeholder="$t('order.referenceNumber')"
                             :disabled="!editable"
                             v-model="value.referenceNumber"/>
        </div>
        <div class="col-12">
          <b-form-group class="mt-0 mb-0" :label="$t('shipment.shipping-date')" label-cols="6">
              <e-form-datepicker ref="shippingDate" :id="id + '-shippingDate'" name="shippingDate" :rules="{
                required: true,
                dateEqualOrAfter: currentDateAsString()
              }"
                                 :placeholder="$t('orders.choose-shipping-date')"
                                 :date-disabled-fn="isHoliday"
                                 :min="new Date()"
                                 :max="new Date(new Date().getFullYear(), new Date().getMonth() + 2, new Date().getDate())"
                                 :disabled="!editable"
                                 v-model="value.shippingDate"/>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group class="mt-0 mb-0" :label="$t('shipment.shipping-time')" label-cols="4">
            <b-form-group :label="$t('time.from')" label-for="shippingTimeFrom" label-cols="3" label-class="text-right">
              <time-input :id="id + '-shippingTimeFrom'" name="shippingTimeFrom" :disabled="!editable" v-model="value.shippingTimeFrom"/>
            </b-form-group>

            <b-form-group :label="$t('time.to')" label-for="shippingTimeUntil" label-cols="3" label-class="text-right">
              <time-input :id="id + '-shippingTimeUntil'" name="shippingTimeUntil" :disabled="!editable" v-model="value.shippingTimeUntil"/>
            </b-form-group>
          </b-form-group>
        </div>

        <div class="col-12">
          <b-form-group class="mt-0 mb-0" :label="$t('shipment.shipping-notice.notice')" label-cols="6">
            <e-form-text-input :id="id + '-shippingNotice'" maxlength="35" name="shippingNotice":disabled="!editable"
                               :placeholder="$t('shipment.shipping-notice.notice')"
                               v-model="value.shippingNotice"/>
          </b-form-group>
        </div>
        <div class="col-12">
          <e-form-select :id="id + '-shippingTerms'" name="shippingTerms" rules="required"
                         :placeholder="$t('orders.choose-shipping-terms')"
                         :disabled="!editable"
                         v-model="value.shippingTermsIdentifier" @input="value.shippingTerms = incoTerms.find(t => t.identifier == $event)?.name">
            <option v-for="(term, idx) in filterIncoTerms" :key="idx" :value="term.identifier">{{
                term.name
              }}
            </option>
          </e-form-select>
        </div>
        <div class="col-5 col-md-6">
          <e-form-text-input :id="id + '-collectOnDelivery.value'" name="collectOnDelivery"
                             type="number"
                             :rules="{
                                regex: /^[0-9]+([\.][0-9]{1,2})?$/,
                                collectOnDelivery: {product: value.product},
                                collectOnDeliveryMaximum: {maximum: collectOnDeliveryCurrency?.collectOnDeliveryMaximum, currency: value.collectOnDelivery?.currency}
                              }"
                             :placeholder="$t('order.collectOnDelivery')"
                             :disabled="!editable || checkCodDisabled"
                             :append="$t('currency.' + (value.collectOnDelivery?.currency || collectOnDeliveryCurrency?.isoCode || 'EUR'))"
                             :value="value.collectOnDelivery?.value" @input="setCollectOnDeliveryValue($event)">
            <template v-slot:append="{additionalClasses}" v-if="editable && !checkCodDisabled">
              <b-dropdown
                  variant="secondary" class="p-0" :class="additionalClasses" toggle-class="input-group-text dropdown-input"
                  :text="$t('currency.' + (value.collectOnDelivery?.currency || collectOnDeliveryCurrency?.isoCode || 'EUR'))">
                <b-dropdown-item v-for="(c, idx) in supportedCurrencies"
                                 @click="setCollectOnDeliveryCurrency(c.isoCode)">
                  {{ c.isoCode }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </e-form-text-input>
        </div>
        <div class="col-7 col-md-6" v-if="value.insuranceValue?.value || !waiverCustomer">
          <e-form-text-input :id="id + '-insuranceValue.value'" name="insuranceValue"
                             type="number"
                             :rules="{regex: /^[0-9]+([\.][0-9]{1,2})?$/}"
                             :placeholder="$t('order.insuranceValue')"
                             :disabled="!editable"
                             :append="$t('currency.' + value.insuranceValue.currency)"
                             v-model="value.insuranceValue.value"/>

        </div>
        <div class="col-12" v-if="!waiverCustomer && !value.insuranceValue?.value">
          <div class="form-row text-danger text-center">
            <div class="col-12">{{ $t('order.insuranceValue.defaultInsurance', {currency: insuranceCurrency?.isoCode, defaultPerKg: $n(insuranceCurrency?.insuranceDefaultPerKg) }) }}</div>
          </div>
        </div>
        <div class="col-12" v-if="value.insuranceValue?.value > (insuranceCurrency?.insuranceThreshold)">
          <div class="form-row text-danger text-center">
            <div class="col-12">{{ $t('order.insuranceValue.adviceText', {currency: insuranceCurrency?.isoCode, threshold: $n(insuranceCurrency?.insuranceThreshold) }) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {dateToString, isHoliday} from "@/util/dateutils";
import {extend, log, ValidationProvider} from '@emons/emons-vue'
import IncoTermCodeService from "@/services/incoTermCode.service"
import TimeInput from "@/components/form-controls/TimeInput.vue";

extend('dateEqualOrAfter', {
  params: ['dateStr'],
  validate(value, {dateStr}) {
    const [year1, month1, day1] = value.split("-")
    const valueDate = new Date(year1, month1 - 1, day1)

    const [year2, month2, day2] = dateStr.split("-")
    const compareDate = new Date(year2, month2 - 1, day2)

    console.log('comparing', value, dateStr)
    if (compareDate.getTime() > valueDate.getTime()) {
      return false
    }
    return true
  }
})

extend('collectOnDelivery', {
  params: ['product'],
  validate(value, {product}) {
    log('debug', 'checking CoD: ', product)

    if (product) {
      if (!product.codEnabled) {
        return false
      }
    }
    return true
  },
})

extend('collectOnDeliveryMaximum', {
  params: ['maximum', 'currency'],
  validate(value, {maximum, currency}) {
    log('debug', 'checking CoD maximum: ', maximum, currency)

    if (!!maximum) {
      if (value > maximum) {
        return false
      }
    }

    return true
  },
})

export default {
  name: "OrderCharacteristicsView",
  components: {TimeInput, ValidationProvider},
  props: {
    value: {
      type: Object,
    },
    id: {
      type: String,
      default: 'orderCharacteristics'
    },
    editable: {
      type: Boolean,
      default: false
    },
    shippingDateOffset: {
      type: Number,
      default: 0
    },
    requiredFields: {
      type: Array,
      default: () => []
    },
    waiverCustomer: {
      type: Boolean,
      default: false
    },
    customerCurrency: {
      type: String,
      default: ''
    },
    branchCurrency: {
      type: String,
      default: ''
    },
    supportedCurrencies: {
      type: Array,
      default: []
    },
    defaultTermNational: {
      type: String,
      default: ''
    },
    defaultTermInternational: {
      type: String,
      default: ''
    },
    shippingTimeFrom: {
      type: String,
      default: ''
    },
    shippingTimeUntil: {
      type: String,
      default: ''
    },
    shippingTimeFromFr: {
      type: String,
      default: ''
    },
    shippingTimeUntilFr: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      incoTerms: [], // fetched from server
    }
  },
  created() {
    IncoTermCodeService.findAll().then(response => {
      this.incoTerms = response?.data?.items
    })
  },
  methods: {
    setCollectOnDeliveryValue(value) {
      if (!this.value.collectOnDelivery) {
        this.value.collectOnDelivery = {value: '', currency:value.collectOnDelivery?.currency || this.collectOnDeliveryCurrency?.isoCode || 'EUR'}
      }
      this.value.collectOnDelivery.value = value
    },
    setCollectOnDeliveryCurrency(currency) {
      if (!this.value.collectOnDelivery) {
        this.value.collectOnDelivery = {value: '', currency: ''}
      }
      this.value.collectOnDelivery.currency = currency
    },
    currentDateAsString() {
      return dateToString(new Date())
    },
    isHoliday: function (ymd, date) {
      return isHoliday(date, this?.value?.sender?.country)
    },
    setShippingTerms: function(incoTerms, defaultTermNational, defaultTermInternational) {
      if (!incoTerms || incoTerms.length == 0) {
        return
      }
      if (!this.editable) {
        return
      }

      // set to none if currently selected shipping terms no longer present
      if (this.value.shippingTerms != '' && incoTerms.filter(term => term.name == this.value.shippingTerms).length == 0) {
        this.value.shippingTermsIdentifier = ''
        this.value.shippingTerms = ''
      }

      const isInternational = (this?.value?.consignee?.country != 'DE') || (this.value?.sender?.country != 'DE')
      const isBelog = (this?.value?.consignee?.emonsCustomerId?.length > 0)
      if (isBelog) {
        // [TK:26269] current consignee is belog, set to "Unfrei"
        let terms
        if (isInternational) {
          terms = incoTerms.find(term => term.identifier == '11')
        } else {
          terms = incoTerms.find(term => term.identifier == '06')
        }
        this.value.shippingTermsIdentifier = terms.identifier
        this.value.shippingTerms = terms.name
      } else {
        let term
        if (isInternational) {
          // 24 = "DAP geliefert benannter Ort"
          let def = null
          if (defaultTermInternational) {
            def = incoTerms.find(term => term.name == defaultTermInternational || term.identifier == defaultTermInternational)
          }
          term = def || incoTerms.find(term => term.identifier == '24')
        } else {
          // 04 = frei Haus
          let def = null
          if (defaultTermNational) {
            def = incoTerms.find(term => term.name == defaultTermNational || term.identifier == defaultTermNational)
          }
          term = def || incoTerms.find(term => term.identifier == '04')
        }
        if (term) {
          this.value.shippingTermsIdentifier = term.identifier
          this.value.shippingTerms = term.name
        } else if (!this.value.shippingTerms) {
          // if still not set then set to first in list, in germany this should be "frei Haus"
          this.value.shippingTermsIdentifier = incoTerms[0].identifier
          this.value.shippingTerms = incoTerms[0].name
        }
      }
      this.$log('DEBUG', 'setShippingTerms - shipping terms', this.value.shippingTerms, 'default nat: ', this.defaultTermNational, 'default inat:', this.defaultTermInternational, 'belog: ' + isBelog, 'international: ' + isInternational)
    },
    updateShippingTime(dateFrom) {
      if (!this.editable) {
        return
      }
      let d = dateFrom.split("-")
      d = new Date(Date.UTC(d[0], d[1] - 1, d[2]))
      if (d.getDay() == 5) {
        this.value.shippingTimeFrom = this.shippingTimeFromFr || this.shippingTimeFrom || ''
        this.value.shippingTimeUntil = this.shippingTimeUntilFr || this.shippingTimeUntil || ''
      } else {
        this.value.shippingTimeFrom = this.shippingTimeFrom || ''
        this.value.shippingTimeUntil = this.shippingTimeUntil || ''
      }
    },
  },
  asyncComputed: {
    async insuranceCurrency() {
      const allCurrencies = this.supportedCurrencies
      const customerCurrency = this.customerCurrency
      const branchCurrency = this.branchCurrency
      const insuranceCurrency = this.value?.insuranceValue?.currency
      const editable = this.editable

      let currencyIsoCode
      if (!!insuranceCurrency) {
        // insurance currency already set - use value
        currencyIsoCode = insuranceCurrency
      } else if (!!customerCurrency && editable) {
        // insurance currency not yet set, try customer currency first
        currencyIsoCode = customerCurrency
        this.value.insuranceValue.currency = currencyIsoCode
      } else if (!!branchCurrency && editable) {
        // customer currency not set, try branch currency
        currencyIsoCode = branchCurrency
        this.value.insuranceValue.currency = currencyIsoCode
      } else {
        // branch currency not available - use 'EUR' as fallback
        currencyIsoCode = 'EUR'
        this.value.insuranceValue.currency = currencyIsoCode
      }

      let currency = null;
      if (allCurrencies) {
        currency = allCurrencies.find(c => c.isoCode == currencyIsoCode)
      }
      if (null === currency) {
        if ('CZK' == currencyIsoCode) {
          currency = {
            isoCode: 'CZK',
            insuranceThreshold: 1000000,
            insuranceDefaultPerKg: 500,
            collectOnDeliveryMaximum: 120000
          }
        } else {
          currency = {
            isoCode: 'EUR',
            insuranceThreshold: 50000,
            insuranceDefaultPerKg: 20,
            collectOnDeliveryMaximum: 5000
          }
        }
      }
      return currency;
    },
    async collectOnDeliveryCurrency() {
      const allCurrencies = this.supportedCurrencies
      const customerCurrency = this.customerCurrency
      const branchCurrency = this.branchCurrency
      const collectOnDeliveryCurrency = this.value?.collectOnDelivery?.currency
      const editable = this.editable

      let currencyIsoCode
      if (!!collectOnDeliveryCurrency) {
        // collect on delivery currency already set - use value
        currencyIsoCode = collectOnDeliveryCurrency
      } else if (!!customerCurrency && editable) {
        // collect on delivery currency not yet set, try customer currency first
        currencyIsoCode = customerCurrency
        this.value.collectOnDelivery.currency = currencyIsoCode
      } else if (!!branchCurrency && editable) {
        // customer currency not set, try branch currency
        currencyIsoCode = branchCurrency
        this.value.collectOnDelivery.currency = currencyIsoCode
      } else {
        // branch currency not available - use 'EUR' as fallback
        currencyIsoCode = 'EUR'
        this.value.collectOnDelivery.currency = currencyIsoCode
      }

      let currency = null;
      if (allCurrencies) {
        currency = allCurrencies.find(c => c.isoCode == currencyIsoCode)
      }
      if (null === currency) {
        if ('CZK' == currencyIsoCode) {
          currency = {
            isoCode: 'CZK',
            insuranceThreshold: 1000000,
            insuranceDefaultPerKg: 500,
            collectOnDeliveryMaximum: 120000
          }
        } else {
          currency = {
            isoCode: 'EUR',
            insuranceThreshold: 50000,
            insuranceDefaultPerKg: 20,
            collectOnDeliveryMaximum: 5000
          }
        }
      }
      return currency;
    },
  },
  computed: {
    filterIncoTerms: function () {
      const isInternational = (this?.value?.consignee?.country != 'DE') || (this.value?.sender?.country != 'DE')
      const incoTerms = this.incoTerms.filter(
          term => term.international == isInternational
      )

      return incoTerms
    },
    checkCodDisabled: function () {
      const sCountry = this?.value?.sender?.country
      const cCountry = this?.value?.consignee?.country
      const codCountryDisabled = !((sCountry == 'CZ' || sCountry == 'SK') && (cCountry == 'CZ' || cCountry == 'SK'))
      const codProductDisabled = this.value.product && !this.value.product.codEnabled
      const codDisabled = codCountryDisabled || codProductDisabled

      this.$log('DEBUG', 'cod - country disabled: ' + codCountryDisabled + " / product disabled: " + codProductDisabled, sCountry, cCountry)
      if (codDisabled) {
        return !this.value?.collectOnDelivery?.value
      } else {
        return codDisabled
      }
    },
  },
  watch: {
    "value.shippingDate": {
      handler: function (newVal) {
        this.updateShippingTime(newVal)
      }
    },
    shippingDateOffset: {
      immediate: true,
      handler: function(newVal) {
        const shippingDateOffset = parseInt(newVal)
        if (!isNaN(shippingDateOffset) && !this.value.shippingDate) {
          let date = new Date()
          date.setDate(date.getDate() + shippingDateOffset)

          let dateString = dateToString(date)
          while (this.isHoliday(dateString, date)) {
            date.setDate(date.getDate() + 1)
            dateString = dateToString(date)
          }
          this.value.shippingDate = dateString
          this.$refs.shippingDate?.setValue(dateString)
        }
      }
    },
    shippingTimeFrom: {
      handler: function() {
        this.updateShippingTime(this.value.shippingDate)
      }
    },
    shippingTimeUntil: {
      handler: function() {
        this.updateShippingTime(this.value.shippingDate)
      }
    },
    shippingTimeFromFr: {
      handler: function() {
        this.updateShippingTime(this.value.shippingDate)
      }
    },
    shippingTimeUntilFr: {
      handler: function() {
        this.updateShippingTime(this.value.shippingDate)
      }
    },
    defaultTermNational: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.$log('DEBUG', 'setShippingTerms: ', this.filterIncoTerms, 'newVal', newVal, 'oldVal', oldVal)
        if (oldVal != newVal) {
          this.setShippingTerms(this.filterIncoTerms, newVal, this.defaultTermInternational)
        }
      }
    },
    defaultTermInternational: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.$log('DEBUG', 'setShippingTerms: ', this.filterIncoTerms, 'newVal', newVal, 'oldVal', oldVal)
        if (oldVal != newVal) {
          this.setShippingTerms(this.filterIncoTerms, this.defaultTermNational, newVal)
        }
      }
    },
    "value.collectOnDelivery.value": function(value) {
      if(value === '') {
        log('debug', 'Reset collectOnDelivery to null as empty...');
        this.value.collectOnDelivery.value = null
      }
    },
    "value.insuranceValue.value": function(value) {
      if(value === '') {
        log('debug', 'Reset insuranceValue to null as empty...');
        this.value.insuranceValue.value = null
      }
    },
    /**
     * Watcher on computed property filterIncoTerms.
     * Unsets invalid valus and sets the default value for the selected inco term.
     * Watcher is required instead of doing it in the computed property because it
     * - a) asynchronously queries default settings for incoterms
     * - b) avoids unnecessary recomputing of cached computed property due to unnecessary reactive dependencies
     *
     * @param newValue the new value for filterIncoTerms.
     * @returns {Promise<void>}
     */
    filterIncoTerms: async function (newValue) {
      this.$log('DEBUG', 'filterIncoTerms', newValue)
      this.setShippingTerms(newValue, this.defaultTermNational, this.defaultTermInternational)
    }
  }
}
</script>

<style scoped>

</style>